/* Importing Bootstrap SCSS file. */
// Global Colors
$white: #ffffff;
$blue: #47A4E4;
$orange: #F5A623;
$green: #56BD5B;
$red: #E05139;

$primary: $blue;
$success: $green;
$danger: $red;
$warning: $orange;
$info: $blue;

$navbar-brand-font-size: 1.5rem;

// Body elements
// $body-bg: #EEF4F9;
// $body-color: #6A6A6A;

@import 'bootstrap/scss/bootstrap';

// Custom styles
.btn-primary, .btn-success, .btn-danger, .btn-warning, .btn-info {
  color: white;
}

.btn:hover {
  color: white;
}

.form-control {
  background-color: #fff !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef !important;
}

a {
  text-decoration: none !important;
}

.nav-pills {
  .nav-link {
    border: 1px solid $blue;
    margin-right: 3px;
  }
}

// Sidebar
$defaultSidebar: #343a40;

.fa {
    cursor: default;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000000;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    line-break: auto
}

.popover.top {
    margin-top: -12px
}

.popover.right {
    margin-left: 12px
}

.popover.bottom {
    margin-top: 12px
}

.popover.left {
    margin-left: -12px
}

.table td {
    vertical-align: middle;
}

.modal {
    z-index: 6000 !important;
}

.chart-canvas {
    min-height: 300px;
}

#custom-sidebar {
  background-color: #343a40 !important;
}

#custom-header {
  background-color: #343a40 !important;
}

@media (min-width: 768px) {
  #custom-header {
    background-color: $blue !important;
  }
}
